import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth-module'
import birthdayreminder from '@/store/bullets/birthdayreminder-module'
import recurringtask from '@/store/bullets/recurringtask-module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    birthdayreminder,
    recurringtask,
  },
})
