import axios from 'axios'
import authHeader from '@/services/auth-header'

export default class RecurringTaskService {
  static async new(recurringtask) {
    return axios
      .post(`${process.env.VUE_APP_BACK_URL}/recurring-event/new`,
        recurringtask,
        { headers: authHeader() })
      .then(response => response.data)
  }

  static getAll() {
    return axios
      .get(`${process.env.VUE_APP_BACK_URL}/recurring-event/get-all`,
        { headers: authHeader() })
      .then(response => response.data)
  }

  static async delete(idRecurringTask) {
    return axios
      .delete(`${process.env.VUE_APP_BACK_URL}/recurring-event/delete/${idRecurringTask}`,
        { headers: authHeader() })
      .then(response => response.data)
  }
}
