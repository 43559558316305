import axios from 'axios'

export default class AuthService {
  static async login(user) {
    return axios
      .post(`${process.env.VUE_APP_BACK_URL}/public/auth/signin`, {
        userName: user.username,
        password: user.password,
      })
      .then(response => {
        if (response.data.jwt) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }

  static logout() {
    localStorage.removeItem('user')
  }

  static register(user) {
    return axios.post(`${process.env.VUE_APP_BACK_URL}/public/auth/signup/`, {
      userName: user.username,
      email: user.email,
      password: user.password,
    })
  }
}

// export default new AuthService()
