import RecurringTaskService from '@/services/bullets/recurringtask.service'

const initialState = { status: { created: false, deleted: false }, recurringtasks: [] }

export default {
  namespaced: true,
  state: initialState,
  actions: {
    new({ commit }, recurringtask) {
      return RecurringTaskService.new(recurringtask).then(
        recurringtask => {
          commit('creationSuccess', recurringtask)

          return Promise.resolve(recurringtask)
        },
        error => {
          console.log(error)
          commit('creationFailure')

          return Promise.reject(error)
        },
      )
    },
    getAll({ commit }) {
      return RecurringTaskService.getAll().then(
        recurringtasks => {
          commit('getAllSuccess', recurringtasks)

          return Promise.resolve(recurringtasks)
        },
        error => {
          console.log(error)
          commit('getAllFailure')

          return Promise.reject(error)
        },
      )
    },
    delete({ commit }, id) {
      return RecurringTaskService.delete(id).then(
        id => {
          commit('deleteSuccess', id)

          return Promise.resolve(id)
        },
        error => {
          console.log(error)
          commit('deleteFailure')

          return Promise.reject(error)
        },
      )
    },
  },
  mutations: {
    creationSuccess(state, recurringtask) {
      state.status.created = true
      state.recurringtasks.push(recurringtask)
      setTimeout(() => { state.status.created = false }, 3000)
    },
    creationFailure(state) {
      state.status.created = false
    },
    getAllSuccess(state, recurringtasks) {
      state.status.getAll = true
      state.recurringtasks = recurringtasks
    },
    getAllFailure(state) {
      state.status.getAll = false
      state.recurringtasks = null
    },
    deleteSuccess(state, idTodelete) {
      state.status.deleted = true
      setTimeout(() => { state.status.deleted = false }, 3000)
      const indexOfObject = state.recurringtasks.findIndex(task => task.id === idTodelete)
      state.recurringtasks.splice(indexOfObject, 1)
    },
    deleteFailure(state) {
      state.status.deleted = false

      // no deletion task
    },
  },
}
