import BirthdayReminderService from '@/services/bullets/birthdayreminder.service'

const initialState = { status: { created: false }, birthdayreminder: null, birthdayreminders: null }

export default {
  namespaced: true,
  state: initialState,
  actions: {
    new({ commit }, birthreminder) {
      return BirthdayReminderService.new(birthreminder).then(
        birthreminder => {
          commit('creationSuccess', birthreminder)

          return Promise.resolve(birthreminder)
        },
        error => {
          console.log(error)
          commit('creationFailure')

          return Promise.reject(error)
        },
      )
    },
    getAll({ commit }) {
      return BirthdayReminderService.getAll().then(
        birthreminders => {
          commit('getAllSuccess', birthreminders)

          return Promise.resolve(birthreminders)
        },
        error => {
          console.log(error)
          commit('getAllFailure')

          return Promise.reject(error)
        },
      )
    },
  },
  mutations: {
    creationSuccess(state, birthdayreminder) {
      state.status.created = true
      state.birthdayreminder = birthdayreminder
      state.birthdayreminders.push(birthdayreminder)
      setTimeout(() => { state.status.created = false }, 5000)
    },
    creationFailure(state) {
      state.status.created = false
      state.birthdayreminder = null
    },
    getAllSuccess(state, birthdayreminders) {
      state.status.getAll = true
      state.birthdayreminders = birthdayreminders
    },
    getAllFailure(state) {
      state.status.getAll = false
      state.birthdayreminders = null
    },
  },
}
